export const professionsTheme = {
    "id": 167,
    "name": "المهن",
    "slug": "professions",
    "description": "يضم مجموعة متنوعة من المهن المختلفة.",
    "textures": {
        "right": "/memoryGame/themes/professions/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/professions/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/professions/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/professions/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/professions/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/professions/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "النجّار",
            "slug": "carpenter",
            "picture": "memoryGame/themes/professions/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/carpenter_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/carpenter_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/carpenter_en.mp3"
            },
            "funFact": "النجّارون يقومون بصنع الأثاث.",
            "info": {
                "description": "النجّار هو متخصص في العمل مع الخشب.",
                "bodyInfo": [
                    "يستخدم الأدوات لقص الخشب وتشكيله.",
                    "يمكن أن يعمل في بناء المنازل.",
                    "يصنع أثاثًا مثل الطاولات والكراسي."
                ],
                "funFacts": [
                    "تاريخ النجارة يعود لآلاف السنين.",
                    "يعتبر النجّار من المهن الأساسية في البناء.",
                    "النجارة تتطلب مهارات دقيقة وإبداع."
                ]
            }
        },
        {
            "id": 2,
            "name": "محامي",
            "slug": "lawyer",
            "picture": "memoryGame/themes/professions/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/lawyer_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/lawyer_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/lawyer_en.mp3"
            },
            "funFact": "المحامي يدافع عن حقوق الناس ويساعدهم في حل مشاكلهم القانونية.",
            "info": {
                "description": "المحامي هو شخص متخصص في القانون ويمثل العملاء في القضايا القانونية.",
                "bodyInfo": [
                    "يعمل في المحاكم والمؤسسات القانونية.",
                    "يكتب المستندات القانونية ويقدم المشورة.",
                    "يحتاج إلى مهارات تحليلية وتواصل قوية."
                ],
                "funFacts": [
                    "أصل مهنة المحاماة يعود إلى العصور الرومانية.",
                    "يلعب المحامون دورًا مهمًا في تحقيق العدالة.",
                    "تتطلب هذه المهنة دراسة مكثفة للقانون."
                ]
            }
        },
        {
            "id": 3,
            "name": "المعلّمة",
            "slug": "teacher",
            "picture": "memoryGame/themes/professions/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/teacher_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/teacher_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/teacher_en.mp3"
            },
            "funFact": "المعلمون يساعدون الطلاب على التعلم.",
            "info": {
                "description": "المعلمة هي شخص يعلم الطلاب في المدارس.",
                "bodyInfo": [
                    "تعمل على توجيه الطلاب ومساعدتهم.",
                    "تدرس مواد مختلفة مثل الرياضيات والعلوم.",
                    "تحتاج إلى صبر ومهارات تواصل جيدة."
                ],
                "funFacts": [
                    "يعتبر المعلمون من أهم الأشخاص في المجتمع.",
                    "يؤثرون في تشكيل مستقبل الأجيال.",
                    "يمكن أن يكون لهم تأثير كبير على حياة الطلاب."
                ]
            }
        },
        {
            "id": 4,
            "name": "ساعي البريد",
            "slug": "mailman",
            "picture": "memoryGame/themes/professions/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/mailman_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/mailman_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/mailman_en.mp3"
            },
            "funFact": "ساعي البريد يوصل الرسائل والطرود.",
            "info": {
                "description": "ساعي البريد هو الشخص الذي يوصل البريد.",
                "bodyInfo": [
                    "يعمل في الهواء الطلق في معظم الأوقات.",
                    "يحتاج إلى معرفة الطرق جيدًا.",
                    "يستخدم وسائل النقل مثل الدراجات أو السيارات."
                ],
                "funFacts": [
                    "تاريخ خدمة البريد يعود لآلاف السنين.",
                    "يعتبر ساعي البريد حلقة وصل بين الناس.",
                    "يساهم في تعزيز التواصل بين الأفراد."
                ]
            }
        },
        {
            "id": 5,
            "name": "المزارع",
            "slug": "farmer",
            "picture": "memoryGame/themes/professions/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/farmer_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/farmer_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/farmer_en.mp3"
            },
            "funFact": "المزارعون يزرعون المحاصيل ويعتنون بالحيوانات.",
            "info": {
                "description": "المزارع هو شخص يعمل في الزراعة.",
                "bodyInfo": [
                    "يزرع المحاصيل مثل القمح والفواكه.",
                    "يعتني بالحيوانات مثل الأبقار والدجاج.",
                    "يحتاج إلى معرفة واسعة عن الزراعة."
                ],
                "funFacts": [
                    "تعتبر الزراعة من أقدم المهن في التاريخ.",
                    "تساعد الزراعة في توفير الغذاء للناس.",
                    "تتطلب الزراعة العمل الجاد والتحمل."
                ]
            }
        },
        {
            "id": 6,
            "name": "الطبيب",
            "slug": "doctor",
            "picture": "memoryGame/themes/professions/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/doctor_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/doctor_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/doctor_en.mp3"
            },
            "funFact": "الأطباء يعالجون المرضى.",
            "info": {
                "description": "الطبيب هو شخص متخصص في الطب.",
                "bodyInfo": [
                    "يعالج المرضى ويوفر الرعاية الصحية.",
                    "يمكن أن يتخصص في مجالات مختلفة مثل الجراحة.",
                    "يحتاج إلى سنوات من التعليم والتدريب."
                ],
                "funFacts": [
                    "تعتبر مهنة الطب من أكثر المهن احترامًا.",
                    "يساهم الأطباء في تحسين صحة المجتمع.",
                    "يمكن أن يكون للأطباء تأثير كبير على حياة المرضى."
                ]
            }
        },
        {
            "id": 7,
            "name": "الحلاّق",
            "slug": "barber",
            "picture": "memoryGame/themes/professions/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/barber_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/barber_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/barber_en.mp3"
            },
            "funFact": "الحلاّق يقدم خدمات تصفيف الشعر.",
            "info": {
                "description": "الحلاق هو شخص يقوم بتصفيف الشعر.",
                "bodyInfo": [
                    "يعمل في صالونات الحلاقة.",
                    "يحتاج إلى مهارات في قص الشعر وتصفيفه.",
                    "يمكن أن يقدم خدمات إضافية مثل الحلاقة."
                ],
                "funFacts": [
                    "تاريخ الحلاقة يعود إلى العصور القديمة.",
                    "تعتبر الحلاقة جزءًا من الثقافة في بعض المجتمعات.",
                    "يستخدم الحلاقون أدوات خاصة مثل المقصات."
                ]
            }
        },
        {
            "id": 8,
            "name": "الشرطة",
            "slug": "police-officer",
            "picture": "memoryGame/themes/professions/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/police-officer_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/police-officer_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/police-officer_en.mp3"
            },
            "funFact": "رجال الشرطة يحافظون على الأمن.",
            "info": {
                "description": "رجال الشرطة يعملون على تطبيق القانون.",
                "bodyInfo": [
                    "يحافظون على السلامة العامة.",
                    "يحققون في الجرائم.",
                    "يحتاجون إلى تدريب خاص ومهارات."
                ],
                "funFacts": [
                    "تاريخ الشرطة يعود لآلاف السنين.",
                    "يعتبر رجال الشرطة جزءًا أساسيًا من المجتمع.",
                    "يعملون في مختلف الظروف والتحديات."
                ]
            }
        },
        {
            "id": 9,
            "name": "الإطفائي",
            "slug": "firefighter",
            "picture": "memoryGame/themes/professions/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/firefighter_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/firefighter_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/firefighter_en.mp3"
            },
            "funFact": "الإطفائيون يحمون الناس من الحرائق.",
            "info": {
                "description": "الإطفائي هو شخص يعمل في إطفاء الحرائق.",
                "bodyInfo": [
                    "يستخدمون معدات خاصة لإطفاء النيران.",
                    "يستجيبون لحالات الطوارئ.",
                    "يتطلب العمل سرعة في التصرف والشجاعة."
                ],
                "funFacts": [
                    "تاريخ الإطفاء يعود إلى العصور القديمة.",
                    "الإطفائيون يتلقون تدريبًا خاصًا.",
                    "يمكن أن يواجهوا مخاطر كبيرة أثناء العمل."
                ]
            }
        },
        {
            "id": 10,
            "name": "المهندس",
            "slug": "engineer",
            "picture": "memoryGame/themes/professions/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/professions/sounds/engineer_ar.mp3",
                "fr": "memoryGame/themes/professions/sounds/engineer_fr.mp3",
                "en": "memoryGame/themes/professions/sounds/engineer_en.mp3"
            },
            "funFact": "المهندسون يبتكرون حلولًا ذكية للمشاكل التقنية.",
            "info": {
                "description": "المهندس هو محترف يقوم بتصميم وتطوير الهياكل، الأنظمة، والآلات.",
                "bodyInfo": [
                    "يعمل في مجالات متنوعة مثل البناء، الإلكترونيات، والبرمجيات.",
                    "يحتاج إلى مهارات رياضية وتقنية عالية.",
                    "يشارك في تطوير المشاريع التقنية المعقدة."
                ],
                "funFacts": [
                    "تعتبر الهندسة واحدة من أقدم المهن في التاريخ.",
                    "المهندسون هم القوة الدافعة وراء الابتكار والتقدم التكنولوجي.",
                    "الهندسة تغطي العديد من التخصصات مثل المدنية، الميكانيكية، والإلكترونية."
                ]
            }
        }

    ]
};
