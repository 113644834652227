export const singleNumbersTheme = {
    "id": 159,
    "name": "الأرقام - الأحاد",
    "slug": "single",
    "description": "يتضمن الأرقام من 0 إلى 9",
    "textures": {
        "right": "/memoryGame/themes/numbers/single/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/numbers/single/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/numbers/single/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/numbers/single/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/numbers/single/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/numbers/single/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الـصفر",
            "slug": "zero",
            "picture": "memoryGame/themes/numbers/single/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/zero_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/zero_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/zero_en.mp3"
            },
            "funFact": "الرقم 1 هو أول عدد طبيعي ويُستخدم لتمثيل الوحدة أو البداية.",
            "info": {
                "description": "الصفر هو رقم فريد من نوعه في جميع أنظمة الأرقام، فهو يمثل غياب أي كمية. كما أنه يُستخدم كأداة ضرورية لتسهيل العمليات الحسابية وتمثيل الأرقام الكبيرة.",
                "bodyInfo": [
                    "الصفر هو أساس النظام العشري.",
                    "بدون الصفر، لن يكون لدينا الأعداد الكبيرة كما نعرفها.",
                    "يستخدم الصفر في الرياضيات والعلوم لإظهار الفراغ والغياب."
                ],
                "funFacts": [
                    "تم اختراع الصفر في الهند خلال القرن الخامس الميلادي.",
                    "يُستخدم الصفر في الأنظمة الرقمية وأجهزة الكمبيوتر بشكل واسع.",
                    "في الرياضيات، الصفر له خصائص فريدة مثل أنه ليس سالبًا ولا موجبًا."
                ]
            }
        },
        {
            "id": 2,
            "name": "الـواحد",
            "slug": "one",
            "picture": "memoryGame/themes/numbers/single/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/one_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/one_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/one_en.mp3"
            },
            "funFact": "الرقم 1 هو أول عدد طبيعي ويُستخدم لتمثيل الوحدة أو البداية.",
            "info": {
                "description": "الرقم 1 هو البداية لكل شيء في الحساب. يُستخدم لتمثيل الوحدة والأساس لأي عدد أكبر. في العديد من الثقافات، يُعتبر رقمًا محظوظًا ويمثل الانفراد والتميز.",
                "bodyInfo": [
                    "الرقم 1 هو أول عدد طبيعي.",
                    "يستخدم الرقم 1 لتسمية الأشخاص الفائزين والمتميزين.",
                    "في الرياضيات، الرقم 1 هو مضاعف محايد، مما يعني أن أي عدد مضروب في 1 يظل كما هو."
                ],
                "funFacts": [
                    "الرقم 1 في الرياضيات هو الأساس لكل الأرقام الأخرى.",
                    "في بعض الثقافات، الرقم 1 يمثل الحظ والانتصار.",
                    "يستخدم الرقم 1 لتمييز الفائزين في المسابقات والسباقات."
                ]
            }
        },
        {
            "id": 3,
            "name": "الـاثنان",
            "slug": "two",
            "picture": "memoryGame/themes/numbers/single/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/two_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/two_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/two_en.mp3"
            },
            "funFact": "الرقم 2 يُعتبر رمزًا للتوازن والشراكة، ويمثل غالبًا الثنائية في الطبيعة مثل الليل والنهار.",
            "info": {
                "description": "الرقم 2 يعبر عن مفهوم الشراكة والتوازن. يُستخدم لتمثيل ازدواجية الأشياء مثل الليل والنهار، والموجب والسالب، والذكر والأنثى.",
                "bodyInfo": [
                    "الرقم 2 هو أول عدد زوجي.",
                    "في الرياضيات، الرقم 2 يمثل التكرار أو الازدواجية.",
                    "في الهندسة، يمكن لرقمين تحديد خط مستقيم واحد."
                ],
                "funFacts": [
                    "الرقم 2 يستخدم في العديد من الألعاب لتمييز الفريقين.",
                    "يرمز الرقم 2 في العديد من الثقافات إلى التوازن بين القوى.",
                    "في الطبيعة، الثنائيات مثل الأجنحة والأقدام والعينين تظهر الرقم 2 بوضوح."
                ]
            }
        },
        {
            "id": 4,
            "name": "الـثلاثة",
            "slug": "three",
            "picture": "memoryGame/themes/numbers/single/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/three_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/three_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/three_en.mp3"
            },
            "funFact": "الرقم 3 يُعتبر في كثير من الثقافات رقم الحظ، ويمثل التكامل والتوازن.",
            "info": {
                "description": "الرقم 3 هو أحد الأرقام التي ترتبط بالحظ في العديد من الثقافات. يُعتبر رمزًا للتكامل والتوازن لأنه يمكن تشكيل مثلث باستخدام ثلاث نقاط.",
                "bodyInfo": [
                    "الرقم 3 هو أول عدد فردي بعد 1.",
                    "في الهندسة، المثلث يتكون من ثلاث زوايا.",
                    "العديد من الأنظمة الطبيعية، مثل الذرات والجزيئات، تحتوي على ثلاثيات."
                ],
                "funFacts": [
                    "في العديد من الألعاب الشعبية، الرقم 3 يُعتبر رقمًا محظوظًا.",
                    "في الدين والأساطير، الرقم 3 يُستخدم لتمثيل التكامل والثالوث.",
                    "المثلثات، الأشكال الهندسية الأساسية، تحتوي على 3 أضلاع."
                ]
            }
        },
        {
            "id": 5,
            "name": "الـأربعة",
            "slug": "four",
            "picture": "memoryGame/themes/numbers/single/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/four_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/four_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/four_en.mp3"
            },
            "funFact": "الرقم 4 يُعتبر رمزًا للتوازن والاستقرار لأنه يُستخدم في بناء الأشكام مثل المربع والمستطيل.",
            "info": {
                "description": "الرقم 4 يرتبط بالتوازن والاستقرار في الطبيعة والهندسة. يُعتبر المربع والمستطيل أحد أقوى الأشكال بسبب تساوي أضلاعها.",
                "bodyInfo": [
                    "الرقم 4 هو ثاني عدد زوجي.",
                    "الأشكال الهندسية مثل المربعات والمستطيلات تحتوي على 4 زوايا.",
                    "البشر لديهم 4 أطراف: ذراعين وساقين."
                ],
                "funFacts": [
                    "الرقم 4 يُستخدم كثيرًا في التصميم المعماري والهندسة بسبب قوته.",
                    "العديد من الألغاز الهندسية تعتمد على الرقم 4.",
                    "في العلوم، العناصر الكيميائية تتواجد غالبًا في مجموعات من 4."
                ]
            }
        },
        {
            "id": 6,
            "name": "الـخمسة",
            "slug": "five",
            "picture": "memoryGame/themes/numbers/single/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/five_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/five_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/five_en.mp3"
            },
            "funFact": "الرقم 5 يُعتبر رمزًا للحواس الخمس، ويستخدم بشكل شائع في التصنيفات الطبيعية.",
            "info": {
                "description": "الرقم 5 يُستخدم لتمثيل العديد من العناصر الطبيعية مثل الحواس الخمس. إنه رمز للانفتاح والحيوية.",
                "bodyInfo": [
                    "الإنسان لديه 5 أصابع في كل يد وقدم.",
                    "هناك 5 حواس أساسية: البصر، السمع، الشم، التذوق، واللمس.",
                    "في الطبيعة، الزهور مثل الزنبق تحتوي غالبًا على 5 بتلات."
                ],
                "funFacts": [
                    "النجوم الخماسية تحتوي على 5 نقاط، وهي رمز شائع في الثقافات المختلفة.",
                    "في الرياضة، العديد من الألعاب تعتمد على 5 لاعبين في الفريق مثل كرة السلة.",
                    "الرقم 5 يرمز أيضًا إلى التوازن بين القوى الطبيعية."
                ]
            }
        },
        {
            "id": 7,
            "name": "الـستة",
            "slug": "six",
            "picture": "memoryGame/themes/numbers/single/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/six_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/six_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/six_en.mp3"
            },
            "funFact": "الرقم 6 يُعتبر عددًا مثاليًا في الرياضيات لأنه يساوي مجموع قواسمه.",
            "info": {
                "description": "الرقم 6 يُعتبر عددًا مثاليًا في الرياضيات لأنه يُساوي مجموع قواسمه. يُستخدم أيضًا في العديد من الأشكال الهندسية مثل السداسيات.",
                "bodyInfo": [
                    "السداسي هو شكل يتكون من 6 جوانب.",
                    "الرقم 6 هو أول عدد مثالي، حيث إن قواسمه (1، 2، 3) تُجمع لتصبح 6.",
                    "في الرياضيات، 6 هو أول عدد يتبع العدد 5."
                ],
                "funFacts": [
                    "في الثقافات المختلفة، الرقم 6 يُعتبر رقم حظ.",
                    "يستخدم الرقم 6 في الألعاب مثل النرد والبطاقات.",
                    "في الرياضيات، يُستخدم الرقم 6 في كثير من المعادلات المعقدة."
                ]
            }
        },
        {
            "id": 8,
            "name": "الـسبعة",
            "slug": "seven",
            "picture": "memoryGame/themes/numbers/single/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/seven_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/seven_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/seven_en.mp3"
            },
            "funFact": "الرقم 7 يُعتبر رقم الحظ في العديد من الثقافات ويُستخدم بشكل شائع في الأساطير.",
            "info": {
                "description": "الرقم 7 يُعتبر رقم الحظ في العديد من الثقافات حول العالم. يُستخدم لتمثيل الكمال والتوازن.",
                "bodyInfo": [
                    "الرقم 7 يمثل غالبًا الحظ والسعادة.",
                    "هناك 7 ألوان في قوس قزح.",
                    "يُستخدم الرقم 7 في العديد من الألعاب والممارسات الروحية."
                ],
                "funFacts": [
                    "في الثقافة الغربية، الرقم 7 يُعتبر رقم حظ.",
                    "الأساطير القديمة غالبًا ما تتضمن الرقم 7 في قصصها.",
                    "الكثير من الأشخاص يفضلون الرقم 7 كرقم محظوظ."
                ]
            }
        },
        {
            "id": 9,
            "name": "الـثمانية",
            "slug": "eight",
            "picture": "memoryGame/themes/numbers/single/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/eight_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/eight_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/eight_en.mp3"
            },
            "funFact": "الرقم 8 يُعتبر رقم الوفرة والتوازن في العديد من الثقافات.",
            "info": {
                "description": "الرقم 8 يُعتبر رقم الوفرة والتوازن، ويستخدم غالبًا في المعاني الروحية. يُعتبر أيضًا رمزًا للقوة.",
                "bodyInfo": [
                    "الرقم 8 هو أول عدد يمثل التوازن بين المادة والروح.",
                    "يستخدم الرقم 8 في الرموز الرياضية مثل اللانهاية.",
                    "في الثقافة الصينية، الرقم 8 يُعتبر رقم حظ."
                ],
                "funFacts": [
                    "في الفلك، 8 هو عدد الكواكب في نظامنا الشمسي.",
                    "في الثقافة الصينية، يتم الاحتفال بالرقم 8 بسبب حظوظه.",
                    "يمكن تقسيم الرقم 8 إلى زوجين من 4."
                ]
            }
        },
        {
            "id": 10,
            "name": "الـتسعة",
            "slug": "nine",
            "picture": "memoryGame/themes/numbers/single/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/single/sounds/nine_ar.mp3",
                "fr": "memoryGame/themes/numbers/single/sounds/nine_fr.mp3",
                "en": "memoryGame/themes/numbers/single/sounds/nine_en.mp3"
            },
            "funFact": "الرقم 9 يُعتبر رمزًا للاكتمال في العديد من الثقافات.",
            "info": {
                "description": "الرقم 9 يُعتبر رمزًا للاكتمال، حيث إنه آخر عدد فردي قبل الوصول إلى الرقم 10. يمثل أيضًا الفهم الحكيم في الثقافة.",
                "bodyInfo": [
                    "الرقم 9 هو العدد الفردي الأخير قبل 10.",
                    "في الرياضيات، جميع الأعداد تُقسم على 9 تعطي باقي 0.",
                    "الكثير من الثقافات تربط الرقم 9 بالكمال."
                ],
                "funFacts": [
                    "في الأساطير، يُعتبر الرقم 9 رقمًا مميزًا.",
                    "يتم استخدام الرقم 9 بشكل متكرر في العديد من الألعاب.",
                    "في بعض الثقافات، الرقم 9 يُعتبر رقم حظ."
                ]
            }
        }
    ]
};
