export const geometricShapesTheme = {
    "id": 161,
    "name": "الأشكال الهندسية",
    "slug": "shapes",
    "description": "يتضمن مجموعة من الأشكال الهندسية.",
    "textures": {
        "right": "/memoryGame/themes/shapes/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/shapes/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/shapes/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/shapes/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/shapes/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/shapes/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الـمُربع",
            "slug": "square",
            "picture": "memoryGame/themes/shapes/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/square_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/square_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/square_en.mp3"
            },
            "funFact": "المربع هو شكل له أربعة جوانب متساوية وزوايا قائمة.",
            "info": {
                "description": "المربع هو شكل هندسي رباعي الأضلاع، جميع زواياه قائمة.",
                "bodyInfo": [
                    "المربع يتميز بتساوي جميع جوانبه.",
                    "يمكن استخدام المربع في التصميمات الهندسية.",
                    "المربع هو الشكل الأساسي في الرياضيات."
                ],
                "funFacts": [
                    "يمكن استخدام المربع في بناء المكعبات.",
                    "المربعات تتواجد في الطبيعة مثل علب الهدايا.",
                    "يمكن أن نرى المربعات في العديد من الألغاز."
                ]
            }
        },
        {
            "id": 2,
            "name": "الـدائرة",
            "slug": "circle",
            "picture": "memoryGame/themes/shapes/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/circle_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/circle_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/circle_en.mp3"
            },
            "funFact": "الدائرة هي شكل بلا زوايا، وكل نقطة فيها تبعد نفس المسافة عن المركز.",
            "info": {
                "description": "الدائرة هي شكل هندسي مستدير، يتميز بتساوي المسافة عن المركز.",
                "bodyInfo": [
                    "تستخدم الدائرة في العديد من التطبيقات الهندسية.",
                    "تعتبر الدائرة رمزًا للكمال.",
                    "الدائرة تُستخدم في تصميم العجلات."
                ],
                "funFacts": [
                    "يُعتبر المثلث القائم على الدائرة أساس العديد من التصميمات.",
                    "الدائرة تتواجد في الطبيعة في شكل الفواكه.",
                    "تمثل الدائرة الزمن (ساعة) في بعض الساعات."
                ]
            }
        },
        {
            "id": 3,
            "name": "الـمثلث",
            "slug": "triangle",
            "picture": "memoryGame/themes/shapes/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/triangle_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/triangle_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/triangle_en.mp3"
            },
            "funFact": "المثلث هو الشكل الهندسي الأكثر ثباتًا.",
            "info": {
                "description": "المثلث هو شكل له ثلاثة جوانب وزوايا.",
                "bodyInfo": [
                    "المثلث هو الشكل الأساسي في الهندسة.",
                    "يوجد ثلاثة أنواع من المثلثات: حاد الزوايا، قائم الزاوية، ومنفرج الزاوية.",
                    "يستخدم المثلث في تصميم الجسور."
                ],
                "funFacts": [
                    "المثلثات تُستخدم في صناعة الأعلام.",
                    "يمثل المثلث شكلًا رمزيًا في العديد من الثقافات.",
                    "يمكن أن نرى المثلثات في الهندسة المعمارية."
                ]
            }
        },
        {
            "id": 4,
            "name": "الـنجمة",
            "slug": "star",
            "picture": "memoryGame/themes/shapes/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/star_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/star_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/star_en.mp3"
            },
            "funFact": "النجمة تُستخدم غالبًا كرمز للتفوق والإنجاز.",
            "info": {
                "description": "النجمة هي شكل يتميز بخمسة أو أكثر من الزوايا.",
                "bodyInfo": [
                    "تعتبر النجوم رموزًا شائعة في الفن والثقافة.",
                    "يتم استخدامها في الأعلام والشعارات.",
                    "تُستخدم النجوم في الفلك لتحديد المواقع."
                ],
                "funFacts": [
                    "تُستخدم النجوم في التصميمات الديكورية.",
                    "يمكن رؤية النجوم في السماء ليلاً.",
                    "تعتبر النجمة رمزًا عالميًا للنجاح."
                ]
            }
        },
        {
            "id": 5,
            "name": "الـسداسي الأضلاع",
            "slug": "hexagon",
            "picture": "memoryGame/themes/shapes/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/hexagon_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/hexagon_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/hexagon_en.mp3"
            },
            "funFact": "سداسي الأضلاع يُستخدم في تصميم العسل.",
            "info": {
                "description": "سداسي الأضلاع هو شكل له ستة جوانب وزوايا.",
                "bodyInfo": [
                    "يستخدم السداسي الأضلاع في الهندسة المعمارية.",
                    "تتواجد الأشكال السداسية في الطبيعة.",
                    "السداسي الأضلاع يُعتبر شكلًا قويًا."
                ],
                "funFacts": [
                    "تُستخدم الأشكال السداسية في تصميم الأثاث.",
                    "يمثل السداسي الأضلاع التوازن في الهندسة.",
                    "يمكن رؤيته في الألغاز والألعاب."
                ]
            }
        },
        {
            "id": 6,
            "name": "الـمستطيل",
            "slug": "rectangle",
            "picture": "memoryGame/themes/shapes/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/rectangle_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/rectangle_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/rectangle_en.mp3"
            },
            "funFact": "المستطيل له جوانب متقابلة متساوية.",
            "info": {
                "description": "المستطيل هو شكل له أربعة جوانب، وزواياه قائمة.",
                "bodyInfo": [
                    "المستطيل يُستخدم في العديد من التطبيقات الهندسية.",
                    "يمثل المستطيل مساحة محددة.",
                    "تُستخدم المستطيلات في التصميم الداخلي."
                ],
                "funFacts": [
                    "المستطيل جزء من العديد من الألعاب.",
                    "يستخدم المستطيل في حسابات المساحة.",
                    "يُعتبر المستطيل شكلاً عمليًا."
                ]
            }
        },
        {
            "id": 7,
            "name": "الـبيضاوي",
            "slug": "oval",
            "picture": "memoryGame/themes/shapes/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/ellipse_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/ellipse_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/ellipse_en.mp3"
            },
            "funFact": "البيضاوي يُعتبر شكلًا جميلاً ويستخدم في العديد من التصميمات.",
            "info": {
                "description": "البيضاوي هو شكل يشبه الدائرة لكنه ممدود.",
                "bodyInfo": [
                    "يستخدم البيضاوي في تصميم المجوهرات.",
                    "البيضاوي يُعتبر جزءًا من التصميمات الحديثة.",
                    "يمكن أن نرى البيضاوي في الطبيعة."
                ],
                "funFacts": [
                    "يمثل البيضاوي الشكل المثالي في الفنون.",
                    "البيضاوي يُستخدم في تصميم الأثاث.",
                    "تُستخدم الأشكال البيضاوية في الفلك."
                ]
            }
        },
        {
            "id": 8,
            "name": "الـمعين",
            "slug": "diamond",
            "picture": "memoryGame/themes/shapes/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/rhombus_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/rhombus_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/rhombus_en.mp3"
            },
            "funFact": "المعين هو شكل له أربعة جوانب، زواياه غير قائمة.",
            "info": {
                "description": "المعين هو شكل يتميز بجوانب متساوية وزوايا متقابلة.",
                "bodyInfo": [
                    "يستخدم المعين في التصميمات الفنية.",
                    "يعتبر المعين جزءًا من الألغاز.",
                    "تُستخدم الأشكال المعينة في العمارة."
                ],
                "funFacts": [
                    "يمثل المعين قيمة في الفنون.",
                    "يستخدم المعين في الألعاب.",
                    "يمكن رؤية الأشكال المعينة في الطبيعة."
                ]
            }
        },
        {
            "id": 9,
            "name": "الـبنتاغون",
            "slug": "pentagon",
            "picture": "memoryGame/themes/shapes/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/pentagon_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/pentagon_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/pentagon_en.mp3"
            },
            "funFact": "البنتاغون هو شكل له خمسة جوانب.",
            "info": {
                "description": "البنتاغون هو شكل يتميز بخمسة جوانب وزوايا.",
                "bodyInfo": [
                    "يستخدم البنتاغون في تصميم المباني.",
                    "يعتبر جزءًا من التصاميم الحديثة.",
                    "يمكن رؤيته في المعمار."
                ],
                "funFacts": [
                    "يمثل البنتاغون قوة وثبات.",
                    "يستخدم في الألعاب.",
                    "يمكن رؤية الأشكال البنتاغونية في الطبيعة."
                ]
            }
        },
        {
            "id": 10,
            "name": "متوازي الأضلاع",
            "slug": "parallelogram",
            "picture": "memoryGame/themes/shapes/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/shapes/sounds/parallelogram_ar.mp3",
                "fr": "memoryGame/themes/shapes/sounds/parallelogram_fr.mp3",
                "en": "memoryGame/themes/shapes/sounds/parallelogram_en.mp3"
            },
            "funFact": "متوازي الأضلاع يحتوي على جوانب متوازية.",
            "info": {
                "description": "متوازي الأضلاع هو شكل هندسي له زوجان من الجوانب المتوازية.",
                "bodyInfo": [
                    "كل جانبين متقابلين فيه متوازيان ومتساويان في الطول.",
                    "يُستخدم في الهندسة والتصميم.",
                    "زاويتا كل ضلعين متقابلين متساويتان."
                ],
                "funFacts": [
                    "متوازي الأضلاع هو جزء من عائلة الأشكال الرباعية.",
                    "يمكن رؤيته في العديد من الأشكال الهندسية الطبيعية والصناعية.",
                    "الأشكال مثل المستطيل والمعين هي أنواع خاصة من متوازي الأضلاع."
                ]
            }
        }
    ]
};
